<template>
  <el-row class="warp">
    <el-col :span="24" class="warp-breadcrum">
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">
          <b>首页</b>
        </el-breadcrumb-item>
        <el-breadcrumb-item>内容管理</el-breadcrumb-item>
        <el-breadcrumb-item>美食</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/foods/list' }">美食</el-breadcrumb-item>
        <el-breadcrumb-item>新增</el-breadcrumb-item>
      </el-breadcrumb>
    </el-col>
    <el-col :span="24" class="warp-main">
      <el-form ref="form" :model="form" label-width="150px" :rules="rules" class="el-form-self">
        <el-row>
          <el-col>
            <el-form-item label="类型:" prop="type">
              <el-select v-model="form.type" size="small" placeholder="请选择">
                <el-option v-for="item in foodsType" :key="item.value" :label="item.label" :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="名称:" prop="name">
              <el-input v-model="form.name" maxlength="50" show-word-limit size="small" auto-complete="off" placeholder="请输入名称" clearable></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="简介:" prop="introduction">
              <el-input v-model="form.introduction" auto-complete="off" placeholder="请输入美食简介" clearable maxlength="1000" show-word-limit size="small"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="所属商户" prop="merchantId">
              <el-select v-model="form.merchantId"  placeholder="请选择">
                <el-option v-for="item in merchantNames" :key="item.merchantId" :label="item.merchantName" :value="item.merchantId" >

                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="图片或者视频:" prop="imageUrl" class="is-required">
              <el-upload class="upload-demo" :action="uploadFileURL" :multiple="false" :limit="1" :on-preview="handleImagePreview" :on-success="handleImageSuccess" :before-upload="beforeImageUpload" :on-remove="handleImageRemove" :on-exceed="handleImageExceed" :file-list="imageUrlList">
                <el-button size="small" type="primary">点击上传</el-button>
                <div slot="tip"  class="el-upload__tip">注意：图片上传.jpg,.png后缀图片文件，图片尺寸大小为750px x 375px，大小不能超过5MB；视频上传大小不超过20MB；图片或视频只可以上传一个</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="首页展示图片:" prop="indexImageUrl" class="is-required">
              <el-upload class="homeBannerImage-uploader" :action="uploadFileURL" :show-file-list="false" :on-success="handleFoodsImageSuccess" :before-upload="beforeFoodsImageUpload">
                <img v-if="indexImageUrl" :src="indexImageUrl" class="homeBannerImage" />
                <i v-else class="el-icon-plus homeBannerImage-uploader-icon"></i>
                <div slot="tip" class="el-upload__tip">注意：点击 + 上传.jpg, .png后缀图片文件。图片尺寸为220px x 220px,大小不超过5MB。</div>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="置顶:" prop="isTop">
              <el-radio-group v-model="form.isTop">
                <el-radio label="0">是</el-radio>
                <el-radio label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>

        <el-row>
          <el-col>
            <el-form-item label="推荐:" prop="recommended">
              <el-radio-group v-model="form.recommended">
                <el-radio label="0">是</el-radio>
                <el-radio label="1">否</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col>
            <el-form-item label="内容:" prop="content">
              <Editor id="tinymce" v-model="form.content" :init="editorInit"></Editor>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row class="form-buttons">
          <el-form-item class="el-form-item-self">
            <el-button type="primary" @click="$router.go(-1)">返回</el-button>
            <el-button type="primary" @click="onSubmit">保存</el-button>
            <el-button type="primary" @click="temporarySubmit">发布</el-button>
          </el-form-item>
        </el-row>
      </el-form>

      <el-dialog :visible.sync="imageUrlDialogVisible">
        <video v-if="uploadType==='video/mp4'" width="100%" height="100%" :src="imageUrl" controls></video>
        <el-image v-else v-for="(image,index) in imageUrlArray" :key="index" style="width: 200px; height: 200px" :src="image" :preview-src-list="imageUrlArray" alt></el-image>
      </el-dialog>

      <el-dialog :visible.sync="videoUrlDialogVisible">
        <audio v-if="form.videoUrl" :src="downloadFileURL+form.videoUrl+'/'" controls />
      </el-dialog>
    </el-col>
  </el-row>
</template>
<script>
import { reqAddFoods,getMerchantNames } from "../../../api/foods-api";
import { downloadFileURL, uploadFileURL } from "../../../api/system-api";
import { SERVER_HOST } from "../../../common/portConfig";
import tinymce from "tinymce/tinymce";
import "tinymce/themes/silver/theme";
import Editor from "@tinymce/tinymce-vue";
import "tinymce/plugins/code";
import "tinymce/plugins/print";
import "tinymce/plugins/preview";
import "tinymce/plugins/importcss";
import "tinymce/plugins/searchreplace";
import "tinymce/plugins/autolink";
import "tinymce/plugins/autosave";
import "tinymce/plugins/save";
import "tinymce/plugins/directionality";
import "tinymce/plugins/visualblocks";
import "tinymce/plugins/visualchars";
import "tinymce/plugins/fullscreen";
import "tinymce/plugins/image";
import "tinymce/plugins/media";
import "tinymce/plugins/link";
import "tinymce/plugins/codesample";
import "tinymce/plugins/table";
import "tinymce/plugins/charmap";
import "tinymce/plugins/hr";
import "tinymce/plugins/pagebreak";
import "tinymce/plugins/nonbreaking";
import "tinymce/plugins/anchor";
import "tinymce/plugins/toc";
import "tinymce/plugins/insertdatetime";
import "tinymce/plugins/advlist";
import "tinymce/plugins/lists";
import "tinymce/plugins/wordcount";
import "tinymce/plugins/imagetools";
import "tinymce/plugins/textpattern";
import "tinymce/plugins/noneditable";
import "tinymce/plugins/help";
import "tinymce/plugins/charmap";
import "tinymce/plugins/quickbars";
import "tinymce/plugins/lists";
import "tinymce/plugins/media";
import "tinymce/plugins/indent2em"
import "tinymce/icons/default/icons";
import "../../../../public/static/tinymce/langs/zh_CN";

export default {
  components: {
    Editor,
  },
  data() {
    return {
      videoUrlDialogVisible: false,
      imageUrlDialogVisible: false,
      imageUrl: "",
      imageUrlArray: [],
      imageUrlList: [],
      merchantNames:'',

      uploadType: "",
      uploadFileURL: uploadFileURL,
      downloadFileURL: downloadFileURL,
      addPath: "/foods/list",
      defaultMsg: "",
      indexImageUrl: "",
      form: {
        imageUrl: "",
        name: "",
        content: "",
        status: "",
        publisher: "",
        isTop: "1",
        type: "",
        recommended: "1",
        indexImageUrl: "",
        merchantId:'',
      },
      foodsType: [
        {
          value: "1",
          label: "传统美食",
        },
        {
          value: "2",
          label: "网红美食",
        },
        {
          value: "3",
          label: "名优产品",
        },
      ],
      rules: {
        name: [
          { required: true, message: "请输入美食名称", trigger: "blur" },
          {
            min: 1,
            max: 50,
            message: "名称不能超过50个字符 ",
            trigger: "blur",
          },
        ],
        type: [
          { required: true, message: "请选择美食类型", trigger: "change" },
        ],
        merchantId: [
          { required: true, message: "请选择所属商户", trigger: "change" },
        ],
        introduction: [
          { required: false, message: "请输入简介", trigger: "blur" },
          {
            min: 1,
            max: 1000,
            message: "简介不能超过1000个字符 ",
            trigger: "blur",
          },
        ],
        indexImageUrl: [
          { required: true, message: "请选择要上传的首页展示图片" },
        ],
        isTop: [{ required: true, message: "请选择是否置顶", trigger: "blur" }],
        recommended: [
          { required: true, message: "请选择是否推荐", trigger: "blur" },
        ],
        content: [
          { required: true, message: "请输入内容", trigger: "blur" },
          {
            min: 1,
            max: 20000,
            message: "内容不能超过20000个字符 ",
            trigger: "blur",
          },
        ],
      },
      editorInit: {
        language_url: "/static/tinymce/langs/zh_CN.js",
        language: "zh_CN",
        skin_url: "/static/tinymce/skins/ui/oxide",
        content_css: "/static/tinymce/skins/content/default/content.css",
        height: 600,
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;",
        plugins:
          "code indent2em print preview importcss searchreplace autolink autosave save directionality visualblocks visualchars fullscreen image link codesample table charmap hr pagebreak nonbreaking anchor toc insertdatetime advlist lists wordcount imagetools textpattern noneditable help charmap quickbars media",
        toolbar:
          "undo redo |code bold italic underline strikethrough indent2em | fontselect fontsizeselect formatselect | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist checklist | forecolor backcolor casechange permanentpen formatpainter removeformat | pagebreak charmap | fullscreen  preview print | image media pageembed template link anchor codesample | a11ycheck ltr rtl | showcomments addcomment",
        file_picker_types: "media file",
        // images_upload_url: '/editorfile/uploadFile',
        autosave_ask_before_unload: false,
        file_picker_callback: function (callback, value, meta) {
          if (meta.filetype === "media") {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            //你可以给input加accept属性来限制上传的文件类型
            //例如：input.setAttribute('accept', '.jpg,.png');
            input.setAttribute("accept", ".mp4");
            input.click();
            input.oninput = function () {
              var file = this.files[0];
              var xhr, formData;
              //console.log(file.name);
              xhr = new XMLHttpRequest();
              xhr.open("POST", SERVER_HOST + "/editorfile/uploadVideoFile");
              // xhr.withCredentials = true ;
              xhr.onload = function () {
                var json;
                if (xhr.status != 200) {
                  alert("上传视频失败");
                  return false;
                }
                json = JSON.parse(xhr.responseText);
                if (!json || typeof json.location != "string") {
                  alert("上传视频失败");
                  return false;
                }
                callback(json.location);
              };
              formData = new FormData();
              if (file.size / 1024 / 1024 > 20) {
                alert("上传视频不能超过20MB");
                return false;
              }
              formData.append("file", file, file.name);
              xhr.send(formData);
            };
          } else if (meta.filetype === "file") {
            var input = document.createElement("input");
            input.setAttribute("type", "file");
            //你可以给input加accept属性来限制上传的文件类型
            //例如：input.setAttribute('accept', '.jpg,.png');
            input.setAttribute("accept", ".xls,.xlsx,.doc,.docx,.pdf");
            input.click();
            input.oninput = function () {
              var file = this.files[0];
              var xhr, formData;
              //console.log(file.name);
              xhr = new XMLHttpRequest();
              xhr.open("POST", SERVER_HOST + "/editorfile/uploadFile");
              xhr.withCredentials = true;
              xhr.onload = function () {
                var json;
                if (xhr.status != 200) {
                  alert("上传失败");
                  return false;
                }
                json = JSON.parse(xhr.responseText);
                if (!json || typeof json.data != "string") {
                  alert("上传失败");
                  return false;
                }
                callback(json.data, { text: "" });
              };
              formData = new FormData();
              if (file.size / 1024 / 1024 > 20) {
                alert("上传失败");
                return false;
              }
              formData.append("file", file, file.name);
              xhr.send(formData);
            };
          }
        },
        images_upload_handler: function (blobInfo, succFun, failFun) {
          var xhr, formData;
          var file = blobInfo.blob(); //转化为易于理解的file对象
          xhr = new XMLHttpRequest();
          xhr.open("POST", SERVER_HOST + "/editorfile/uploadFile", true);
          xhr.withCredentials = true;
          xhr.onload = function () {
            var json;
            if (xhr.status != 200) {
              failFun("上传图片失败");
              return;
            }
            json = JSON.parse(xhr.responseText);
            if (!json || typeof json.data != "string") {
              failFun("上传图片失败");
              return;
            }
            succFun(json.data);
          };
          formData = new FormData();
          formData.append("file", file, file.name);
          xhr.send(formData);
        },
      },
    };
  },

  methods: {
    beforeFoodsImageUpload(file) {
      const isValidType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png";
      const isLt1M = file.size / 1024 / 1024 < 5;
      if (!isValidType) {
        this.$message.error("不支持改类型的文件上传。");
      }
      if (!isLt1M) {
        this.$message.error("上传文件大小不能超过 5MB!");
      }
      return isValidType && isLt1M;
    },
    handleFoodsImageSuccess(response) {
      if (response.code === 1) {
        this.form.indexImageUrl = response.data;
        this.indexImageUrl = downloadFileURL + response.data + "/";
        //console.log(this.indexImageUrl);
        this.$refs.form.validateField("indexImageUrl");
      }
    },
    //-----------------图片或者视频
    beforeImageUpload(file) {
      const isValidType =
        file.type === "image/jpeg" ||
        file.type === "image/jpg" ||
        file.type === "image/png" ||
        file.type === "video/mp4";
      this.uploadType = file.type;
      let num = 5;
      if (this.uploadType === "video/mp4") {
        num = 20;
      }
      const isLt20M = file.size / 1024 / 1024 < num;
      if (!isValidType) {
        this.$message.error("不支持该类型的文件上传。");
        return false;
      }
      if (!isLt20M) {
        this.$message.error("上传文件大小不能超过 " + num + "MB!");
        return false;
      }
      return true;
    },

    handleImagePreview(file) {
      this.imageUrl = downloadFileURL + file.response.data + "/";
      this.imageUrlDialogVisible = true;
    },
    handleImageSuccess(response, file, fileList) {
      var that = this;
      if (response.code === 1) {
        that.imageUrlList = fileList;
        that.imageUrlArray.push(downloadFileURL + response.data + "/");
        this.$refs.form.validateField("videoName");
      }
    },
    handleImageRemove(file, fileList) {
      var index = this.imageUrlArray.indexOf(
        downloadFileURL +
          file.response.data.substring(
            file.response.data
              .substring(0, file.response.data.length - 2)
              .lastIndexOf("/") + 1,
            file.response.data.length
          ) +
          "/"
      );
      if (index !== -1) {
        this.imageUrlArray.splice(index, 1);
      }
    },
    handleImageExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 1 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      );
    },
    onSubmit() {
      var that = this;

      if (that.form.content.length > 20000) {
        that.$message({
          type: "warning",
          message: "内容不能超过20000个字符",
        });
        return;
      }

      that.form.status = "1";
      that.form.publisher = sessionStorage.getItem("Authorization");
      delete that.form["createTime"];
      delete that.form["updateTime"];
      var imageUrls = [];
      that.imageUrlArray.forEach(function (obj) {
        imageUrls.push(
          obj.substring(
            obj.substring(0, obj.length - 2).lastIndexOf("/") + 1,
            obj.length - 1
          )
        );
      });
      that.form.imageUrl = imageUrls.join(",");
      var para = Object.assign({}, that.form);
      reqAddFoods(para).then((res) => {
        if (res.data.code === 1) {
          that.$message({
            message: "保存成功",
            type: "success",
          });
          that.$router.push({ path: that.addPath });
        } else {
          that.$message({
            message: "保存失败",
            type: "error",
          });
        }
      });
    },
    temporarySubmit() {
      var that = this;
      this.$refs.form.validate((valid) => {
        var imageUrls = [];
        that.imageUrlArray.forEach(function (obj) {
          imageUrls.push(
            obj.substring(
              obj.substring(0, obj.length - 2).lastIndexOf("/") + 1,
              obj.length - 1
            )
          );
        });
        that.form.imageUrl = imageUrls.join(",");
        if (valid) {
          if (that.form.content.length > 20000) {
            that.$message({
              type: "warning",
              message: "内容不能超过20000个字符",
            });
            return;
          }
          if (!that.form.imageUrl) {
            that.$message({
              message: "请上传图片或视频",
              type: "warning",
            });
            return false;
          }
          that.form.status = "0";
          that.form.publisher = sessionStorage.getItem("Authorization");
          delete that.form["createTime"];
          delete that.form["updateTime"];
          var para = Object.assign({}, that.form);
          reqAddFoods(para).then((res) => {
            if (res.data.code === 1) {
              that.$message({
                message: "发布成功",
                type: "success",
              });
              that.$refs.form.resetFields();
              that.$router.push({ path: that.addPath });
            } else {
              that.$message({
                message: "发布失败",
                type: "error",
              });
            }
          });
        } else {
          return false;
        }
      });
    },
    getMerchantNames:function(){
      getMerchantNames().then(res=>{
           if (res.data.code === 1) {
            this.merchantNames = res.data.data;

            } else {
              that.$message({
                message: "查询失败",
                type: "error",
              });
            }
      })

    }
  },
  mounted() {
    tinymce.init({});
    this.getMerchantNames();
  },
};
</script>
